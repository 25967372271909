import React from "react"
import { css } from "@emotion/core"
import { FileText } from "react-feather"

import Layout from "../components/layout/"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"

import publicityMaterials from "../cms/publicity-materials"

export default () => (
  <Layout>
    <Head title="Publicity Materials" />

    <PageHeading text="Publicity Materials" centered />

    <div
      css={css`
        max-width: 16rem;
        margin: 0 auto;
      `}
    >
      {publicityMaterials.materials.map(item => (
        <div
          css={css`
            margin-bottom: .5rem;
            max-width: max-content;
          `}
          key={item.file}
        >
          <div
            css={css`
              display: inline-block;
              margin-right: 1rem;
              vertical-align: -30%;

              svg {
                height: 1.3rem;
                width: auto;
              }

              > * {
                stroke: var(--text-light-gray);
                stroke-width: 2px;
              }
            `}
          >
            <FileText />
          </div>
          
          <a
            href={"/" + item.file}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p
              css={css`
                display: inline-block;
                vertical-align: middle;
                text-decoration: underline;
              `}
            >
              {item.name}
            </p>
          </a>
        </div>
      ))}
    </div>
  </Layout>
)